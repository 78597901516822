// extracted by mini-css-extract-plugin
export var alignLeft = "s_qh d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_qj d_bH";
export var textAlignLeft = "s_r7";
export var textAlignCenter = "s_r8";
export var textAlignRight = "s_r9";
export var hoursInnerWrapperAlt = "s_sb d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "s_sc d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "s_sd d_dw";
export var titleMargin = "s_sf d_cw";
export var hoursInnerInnerWrapper = "s_sg d_cz";