// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rQ d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_rR d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rS d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rT d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rV d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rW d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rX d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rY y_rY";
export var heroExceptionNormal = "r_rZ y_rZ";
export var heroExceptionLarge = "r_r0 y_r0";
export var Title1Small = "r_r1 y_r1 y_sw y_sx";
export var Title1Normal = "r_r2 y_r2 y_sw y_sy";
export var Title1Large = "r_r3 y_r3 y_sw y_sz";
export var BodySmall = "r_r4 y_r4 y_sw y_sQ";
export var BodyNormal = "r_r5 y_r5 y_sw y_sR";
export var BodyLarge = "r_r6 y_r6 y_sw y_sS";